import {
  MealFeaturesRoot,
  MealFeaturesContainer,
  Column,
  BalancedImageWrapper,
  NutritionImageWrapper,
  UsdaImageWrapper,
  ShipmentImageWrapper,
  BalancedText,
  NutritionText,
  UsdaText,
  ShipmentText,
  ImageColumn,
} from './MealFeatures.styles'
import { useTranslation } from 'hooks/useTranslation'
import Image from 'next/image'
import balanced from '../../../../../public/assets/icons/brand-refresh/heart.svg'
import nutritionist from '../../../../../public/assets/icons/brand-refresh/health-cross.svg'
import steak from '../../../../../public/assets/icons/brand-refresh/steak.svg'
import shipment from '../../../../../public/assets/icons/brand-refresh/truck.svg'

export const MealFeatures = () => {
  const { t } = useTranslation()

  return (
    <MealFeaturesRoot>
      <MealFeaturesContainer>
        <Column
          gap="2"
          justifyContent={{ '@initial': 'flex-start', '@bp3': 'center' }}
          alignItems="center"
        >
          <ImageColumn>
            <BalancedImageWrapper>
              <Image layout="fill" src={balanced} alt={t('home:benefits.balanced-image')} />
            </BalancedImageWrapper>
          </ImageColumn>

          <BalancedText size="body" weight="regular"  color="tertiary-200">
            {t('home:benefits.balanced')}
          </BalancedText>
        </Column>

        <Column
          gap="2"
          justifyContent={{ '@initial': 'flex-start', '@bp3': 'center' }}
          alignItems="center"
        >
          <ImageColumn>
            <NutritionImageWrapper>
              <Image layout="fill" src={nutritionist} alt={t('home:benefits.nutritionist-image')} />
            </NutritionImageWrapper>
          </ImageColumn>

          <NutritionText size="body" weight="regular"  color="tertiary-200">
            {t('home:benefits.nutritionist-v2')}
          </NutritionText>
        </Column>

        <Column
          gap="2"
          justifyContent={{ '@initial': 'flex-start', '@bp3': 'center' }}
          alignItems="center"
        >
          <ImageColumn>
            <UsdaImageWrapper>
              <Image layout="fill" src={steak} alt={t('home:benefits.usda-image')} />
            </UsdaImageWrapper>
          </ImageColumn>

          <UsdaText size="body" weight="regular"  color="tertiary-200">
            {t('home:benefits.usda')}
          </UsdaText>
        </Column>

        <Column
          gap="2"
          justifyContent={{ '@initial': 'flex-start', '@bp3': 'center' }}
          alignItems="center"
        >
          <ImageColumn>
            <ShipmentImageWrapper>
              <Image layout="fill" src={shipment} alt={t('home:benefits.shipment-image')} />
            </ShipmentImageWrapper>
          </ImageColumn>
          <ShipmentText size="body" weight="regular"  color="tertiary-200">
            {t('home:benefits.shipment-v2')}
          </ShipmentText>
        </Column>
      </MealFeaturesContainer>
    </MealFeaturesRoot>
  )
}

MealFeatures.displayName = 'MealFeatures'
