import { Text } from 'components/Text'
import useTranslation from 'next-translate/useTranslation'
import { BannerText } from 'components/BannerText'
import { styled } from 'stitches.config'

export const NutritionClaim = () => {
  const { t } = useTranslation()

  const Header = styled(Text, {
    maxWidth: '380px',
    margin: '0 auto',

    '@bp2': {
      maxWidth: '980px',
    },

    '@bp3': {
      maxWidth: '1100px',
    },
  })

  return (
    <BannerText background="primary-700">
        <Header 
            size={{ '@initial': 'h3', '@bp2': 'h1' }} 
            color="accent-white" 
            align="center"
            font="display"
          >
            {t('home:nutrition-claim')}
          </Header>
    </BannerText>
  )
}

NutritionClaim.displayName = 'NutritionClaim'