import { useEffect, useState, useRef, RefObject } from 'react'

type UseObserverProps = {
    selector: string
    threshold?: number
};

export const useObserver = ({ selector, threshold = 0.25 }: UseObserverProps) => {
 const [isIntersecting, setIsIntersecting] = useState(false)

 const handleIntersect = (entries: IntersectionObserverEntry[]) => {
    setIsIntersecting(false)

    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            setIsIntersecting(true)
            return
        }
    })
 }

  useEffect(() => {
    const targetElements = document.querySelectorAll(selector)
    
    const options = {
        root: null,
        rootMargin: '0px',
        threshold
    }

    if (targetElements.length === 0 || !window) return

    const observer = new IntersectionObserver(handleIntersect, options)

    targetElements.forEach(element => observer.observe(element))

    return () => observer.disconnect()

  }, [selector, threshold])

  return isIntersecting
}
