import { styled } from 'stitches.config'

export const BannerRoot = styled('div', {
  width: '100%',
  padding: '$6 $3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@bp2': {
    padding: '$8 $4',
  },
})
