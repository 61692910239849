import { styled } from 'stitches.config'
import { Text } from 'components/Text'
import { Stack } from 'components/Stack'

export const PetBenefitsRoot = styled('div', {
  width: '100%',
  backgroundColor: '$accent-white',
  padding: '$10 0 0 0',

  '@bp2': {
    padding: '$6 0 0 0',
  },
})

export const PetBenefitsContainer = styled('div', {
  position: 'relative',
  maxWidth: '500px',
  margin: '0 auto',
  backgroundColor: '$tertiary-200',
  borderRadius: '40px 40px 0px 0px',
  padding: '$8 0 $5 0',
  zIndex: '1',

  '@bp2': {
    maxWidth: '1292px',
    border: '1px solid $primary-700',
    borderRadius: '100px',
    padding: '$9 0 $8 0',
  },
})

export const Title = styled(Text, {
  width: '100%',
  textAlign: 'center',
  maxWidth: '300px',
  '@bp2': {
    maxWidth: '650px',
  },
})

export const Subtitle = styled(Text, {
  width: '100%',
  textAlign: 'center',
  maxWidth: '340px',
  '@bp2': {
    maxWidth: '550px',
  },
})

export const BenefitList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  justifyContent: 'center',
  padding: '$3 $2 0 $2',
  gap: '0',
  width: '100%',
  maxWidth: '320px',

  '@bp2': {
    flexDirection: 'row',
    padding: '$4 0 0 0',
    gap: '$12',
    maxWidth: 'none',
  },
})

export const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
})

export const Row = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '$1',
  padding: '$2',
})

export const ImageContainer = styled('div', {
  width: '30px',
  height: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const BadgeImage = styled('div', {
  position: 'absolute',
  top: '-62px',
  right: '0',
  left: '0',
  textAlign: 'center',
  margin: 'auto',
  display: 'block',
  transform: 'rotate(10deg)',
  width: '130px',

  '@bp2': {
    display: 'none',
  },

  '@bp3': {
    display: 'block',
    left: 'unset',
    top: '20px',
    right: '20px',
    width: '200px',
  },
})

export const Quote = styled(Stack, {
  backgroundColor: '$primary-700',
  borderRadius: '100px 100px 0px 0px',
  padding: '$14 $4 $9 $4',
  width: '100%',
  margin: 'auto',
  marginTop: '-80px',
  zIndex: '0',
})

export const QuoteText = styled(Text, {
  paddingBottom: '$2',
  paddingTop: '$6',
  maxWidth: '1050px',
  margin: 'auto',
})
