import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'
import { Text } from 'components/Text'
import { Box } from 'components/Box'

export const IngredientsRoot = styled('div', {
  width: '100%',
  backgroundColor: '$accent-white',
  padding: '$2 0 $8 0',

  '@bp2': {
    padding: '$5 $2 $8 $2',
  },
})

export const IngredientsContainer = styled('div', {
  maxWidth: '500px',
  margin: '0 auto',

  '@bp2': {
    maxWidth: '1292px',
  },
})

export const Title = styled(Text, {
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  marginBottom: '$3',
  maxWidth: '300px',
  '@bp2': {
    marginBottom: '$8',
    maxWidth: 'none',
  },
})

export const Section = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  maxWidth: '1292px',
  margin: 'auto',
  flexDirection: 'column',
  gap: '$3',

  '@bp2': {
    flexDirection: 'row',
    gap: '0',
  },

  variants: {
    reverse: {
      true: {
        flexDirection: 'column-reverse',
        '@bp2': {
          flexDirection: 'row',
        },
      },
    },
  },
})

export const Benefits = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: '$4',
  padding: '0 $2 0 $3',
  width: '100%',

  '@bp1': {
    padding: '0 $2 0 $4',
  },

  '@bp2': {
    width: '50%',
  },

  '@bp3': {
    padding: '0 $4 0 $7',
    gap: '$8',
  },
})

export const BenefitsRow = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$1',
})

export const BenefitText = styled(Text, {
  width: '100%',
  maxWidth: '380px',
})

export const TopImageWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '@bp2': {
    width: '50%',
  },
})

export const BottomImageWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  '@bp2': {
    width: '50%',
  },
})

export const Proteins = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '$1',
  width: '100%',
  padding: '0 $2 $4 $2',
  '@bp2': {
    width: '50%',
    padding: '$4',
    gap: '$5',
  },
  '@bp3': {
    padding: '$8',
  },
})

export const ProteinTitle = styled(Text, {
  width: '100%',
  maxWidth: '340px',

  '@bp2': {
    maxWidth: '540px',
  },
})

export const Spacer = styled('div', {
  width: '100%',
  height: '$7',

  '@bp3': {
    height: '$9',
  },
})

export const ButtonWrapper = styled('div', {
  textAlign: 'center',
  paddingTop: '0',

  '@bp2': {
    paddingTop: '$4',
  },

  '@bp3': {
    paddingTop: '$7',
  },
})

export const ProteinSelectionV2 = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '$1',
  paddingTop: '$2',

  '@bp2': {
    paddingTop: '0',
    gap: '$2',
  },
})

export const ProteinImageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

export const ProteinImagesRow = styled('div', {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '12px',
  flexWrap: 'wrap',

  '@bp2': {
    gap: '20px',
  },
})
