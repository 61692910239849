import { styled } from 'stitches.config'
import { Stack } from 'components/Stack'

export const HeroRoot = styled('div', {
  backgroundColor: '$tertiary-200',
})

export const ImageColumn = styled('div', {
  width: '100%',
  display: 'flex',
  '@bp2': {
    width: '49.2%',
  }
})

export const ImageWrapper = styled('div', {
  position: 'relative',
  display: 'block',
  lineHeight: 0,
  width: '100%',
  maxWidth: 'none',
  aspectRatio: '5/3',

  '& img': {
    objectPosition: '0% 10%',
  },

  '@bp2': {
   aspectRatio: '1062/756',
    '& img': {
      objectPosition: 'unset',
    }
  }
})

export const DesktopImageColumn = styled('div', {
  display: 'none',
  position: 'relative',
  '@bp2': {
    width: '49.2%',
    display: 'block',
    lineHeight: 0,
  },
})

export const MobileImageColumn = styled('div', {
  display: 'block',
  width: '100%',
  margin: 'auto',
  textAlign: 'center',
  '@bp2': {
    display: 'none',
  },
})

export const CtaColumn = styled(Stack, {
  padding: '$2 $2 $4 $2',
  width: '100%',
  '@bp1': {
    padding: '$2 $4 $4 $4',
  },
  '@bp2': {
    width: '50.8%',
  },
})

export const Layout = styled(Stack, {
  margin: 'auto',
  maxWidth: '500px',
  '@bp2': {
    maxWidth: '2400px',
  },
})

export const ButtonWrapper = styled('div', {
  paddingTop: '$1',
  '@bp2': {
    paddingTop: '$2',
  },
})

export const HeroHeader = styled('div', {
  width: '100%',
  maxWidth: '360px',
  '@bp2': {
    maxWidth: '370px',
  },
  '@bp3': {
    maxWidth: '500px',
  },
  '@bp3_1': {
    maxWidth: '540px',
  },
})
