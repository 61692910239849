import Image, { StaticImageData } from 'next/image'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { useTranslation } from 'hooks/useTranslation'
import {
  IngredientsRoot,
  IngredientsContainer,
  Title,
  Section,
  BenefitsRow,
  Benefits,
  BenefitText,
  TopImageWrapper,
  Proteins,
  ProteinTitle,
  BottomImageWrapper,
  Spacer,
  ButtonWrapper,
  ProteinSelectionV2,
  ProteinImageContainer,
  ProteinImagesRow,
} from './Ingredients.styles'
import { Icon } from 'components/Icon'
import { IconName } from 'components/Icon/iconNames'
import servingTime from '../../../../../public/assets/home/raw-meat-dog-food-preparation.webp'
import rawFoodPackaging from '../../../../../public/assets/home/freeze-dried-ingredient-preparation.webp'
import Trans from 'next-translate/Trans'
import { Stack } from 'components/Stack'
import Link from 'next/link'
import { TranslationKeys } from 'locales/translationKeys'
import beef from '../../../../../public/assets/icons/brand-refresh/beef.png'
import venison from '../../../../../public/assets/icons/brand-refresh/venison.png'
import lamb from '../../../../../public/assets/icons/brand-refresh/lamb.png'
import turkey from '../../../../../public/assets/icons/brand-refresh/turkey.png'
import chicken from '../../../../../public/assets/icons/brand-refresh/chicken.png'
import duck from '../../../../../public/assets/icons/brand-refresh/duck.png'

export const Ingredients = () => {
  const { t } = useTranslation()

  const benefits: TranslationKeys[] = [
    'home:properties.real-ingredients',
    'home:properties.minimal-processing',
    'home:properties.vet-approved',
    'home:properties.cold-pressure'
  ]

  type proteinType = {
    icon: IconName
    label: string
  }

  type proteinIconV2 = {
    imageSrc: StaticImageData
    imageAlt: TranslationKeys
    link: string
  }

  const frozen: proteinIconV2[] = [
    {
      imageSrc: beef,
      imageAlt: 'home:proteins.beef',
      link: '/recipes/beef',
    },
    {
      imageSrc: turkey,
      imageAlt: 'home:proteins.turkey',
      link: '/recipes/turkey',
    },
    {
      imageSrc: chicken,
      imageAlt: 'home:proteins.chicken',
      link: '/recipes/chicken',
    },
    {
      imageSrc: venison,
      imageAlt: 'home:proteins.venison',
      link: '/recipes/venison',
    },
    {
      imageSrc: duck,
      imageAlt: 'home:proteins.duck',
      link: '/recipes/duck',
    },
    {
      imageSrc: lamb,
      imageAlt: 'home:proteins.lamb',
      link: '/recipes/lamb',
    },
  ]

  const freezeDried: proteinIconV2[] = [
    {
      imageSrc: beef,
      imageAlt: 'home:proteins.beef',
      link: '/recipes/freeze-dried-raw-beef',
    },
    {
      imageSrc: chicken,
      imageAlt: 'home:proteins.chicken',
      link: '/recipes/freeze-dried-raw-chicken',
    },
  ]

  return (
    <IngredientsRoot>
      <IngredientsContainer>
        <Stack justifyContent="center">
          <Title font="display" size={{ '@initial': 'h3', '@bp2': 'h1' }}>
            {t('home:claim.title')}
          </Title>
        </Stack>
        <Section>
          <TopImageWrapper>
            <Image
              alt={t('home:properties.image')}
              src={servingTime}
              width={633}
              height={438}
            />
          </TopImageWrapper>
          <Benefits>
            {benefits.map((benefit, index) => (
              <BenefitsRow key={index}>
                <Icon size="4" color="primary-500" name="checkbox-circle-line" />
                <BenefitText size="body">
                  <Trans
                    i18nKey={benefit}
                    components={{ bold: <span style={{ fontWeight: 'bold' }} /> }}
                  />
                </BenefitText>
              </BenefitsRow>
            ))}
          </Benefits>
        </Section>
        <Spacer />
        <Section reverse>
          <Proteins>
            <ProteinTitle size="body">
              {t('home:proteins.title')}
            </ProteinTitle>
            
              <Stack direction="column" gap="3">
                <ProteinSelectionV2>
                  <Text font="label" transform="uppercase" size="h5">
                    {t('home:proteins.frozen')}
                  </Text>
                  <ProteinImagesRow>
                    {frozen.map((protein, index) => (
                      <Link passHref key={index} href={protein.link}>
                        <ProteinImageContainer>
                          <Image src={protein.imageSrc} alt={t(protein.imageAlt)} />
                        </ProteinImageContainer>
                      </Link>
                    ))}
                  </ProteinImagesRow>
                </ProteinSelectionV2>
                <ProteinSelectionV2>
                  <Text font="label" transform="uppercase" size="h5">
                    {t('home:proteins.freeze-dried')}
                  </Text>
                  <ProteinImagesRow>
                    {freezeDried.map((protein, index) => (
                      <Link passHref key={index} href={protein.link}>
                        <ProteinImageContainer>
                          <Image src={protein.imageSrc} alt={t(protein.imageAlt)} />
                        </ProteinImageContainer>
                      </Link>
                    ))}
                  </ProteinImagesRow>
                </ProteinSelectionV2>
              </Stack>
          
          </Proteins>
          <BottomImageWrapper>
            <Image
              width={633}
              height={438}
              src={rawFoodPackaging}
              alt={t('home:proteins.image-v2')}
            />
          </BottomImageWrapper>
        </Section>
        <ButtonWrapper>
          <Link href="/meal-plan" passHref>
            <Button as="a" className='cta'>{t('home:ending.CTA')}</Button>
          </Link>
        </ButtonWrapper>
      </IngredientsContainer>
    </IngredientsRoot>
  )
}

Ingredients.displayName = 'Ingredients'
