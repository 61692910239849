import { ColorTokens } from 'stitches.config'
import { BannerRoot } from './BannerText.styles'

type BannerTextProps = {
  children: React.ReactNode
  background: ColorTokens
}

export const BannerText = ({
  background,
  children,
}: BannerTextProps) => {
  return (
    <BannerRoot css={{ backgroundColor: `$${background}` }}>
      {children}
    </BannerRoot>
  )
}
